import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import tw from 'twin.macro'
import slugify from 'slugify'
import { format, parseISO } from 'date-fns'
import { sv } from 'date-fns/locale'
import AnimateText from '../../src/components/AnimateText'
import Seo from '../../src/components/Seo'
import useGravatar from '../hooks/useGravatar'

const Post = ({ pageContext: { news, allNews = [] } }) => {
	const url = useGravatar()

	return (
		<Fragment>
			<Seo title={news.title} article={true} />
			<div className="container mx-auto pt-12 md:pt-20 pb-12">
				<div className="lg:w-10/12 mx-auto px-4 sm:px-6">
					<h1 className="lg:max-w-7/12 text-3xl md:text-4xl lg:text-5xl font-futura font-semibold leading-tight mb-6 lg:w-6/12">
						<AnimateText>{news.title}</AnimateText>
					</h1>
					<div className="flex items-center mb-8">
						<img
							className="h-12 w-12 rounded-full mr-2"
							alt={news.user?.name}
							layout="fixed"
							height={48}
							width={48}
							src={url}
						/>
						<div>
							<h4 className="text-sm font-medium leading-tight">
								{news.user?.name}
							</h4>
							<span className="text-sm text-gray-700 leading-tight">
								{format(parseISO(news.date), 'd MMMM yyyy', {
									locale: sv,
								})}
							</span>
						</div>
					</div>
					<div className="flex flex-wrap justify-between">
						<div className="w-full lg:w-7/12 mb-8">
							<ArticleContainer
								dangerouslySetInnerHTML={{ __html: news.body }}
							/>
						</div>
						<div className="w-full lg:w-4/12">
							<h2 className="text-xs sm:text-sm text-blue-600 uppercase font-semibold mb-2">
								Fler nyheter
							</h2>
							{allNews.slice(0, 5).map(news => (
								<Fragment key={news.id}>
									<div className="py-6">
										<h2 className="text-lg leading-tight font-medium">
											<Link
												to={`/nyheter/${slugify(
													news.title,
													{
														lower: true,
													},
												)}`}
											>
												{news.title}
											</Link>
										</h2>
									</div>
									<div className="flex w-full h-px  bg-gradient-to-r from-transparent to-transparent via-gray-600" />
								</Fragment>
							))}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

const ArticleContainer = styled.article`
	p {
		${tw`text-lg mb-6`}
	}

	div {
		${tw`text-lg mb-6`}
	}

	h1 {
		${tw`font-futura text-2xl font-bold mb-2 mt-4`}
	}

	h2 {
		${tw`font-futura text-2xl font-bold mb-2 mt-4`}
	}

	h3,
	h4,
	h5 {
		${tw`text-lg font-medium mb-2 mt-4`}
	}

	ul {
		${tw`list-disc text-lg pl-12 pb-6`}
	}

	li {
		${tw`pb-2`}
	}

	.gatsby-resp-image-wrapper {
		${tw`rounded-md w-full mb-6 overflow-hidden`}
	}
	img {
		${tw`rounded-lg w-full mb-6`}
	}

	a {
		${tw`text-blue-600 underline font-medium`}
	}

	table {
		${tw`relative w-full border border-gray-400 rounded-lg border-collapse overflow-hidden mb-12`}
	}

	tr {
		${tw`text-left`}
	}

	thead {
		${tw`bg-gray-300`}
	}

	th {
		${tw`bg-gray-300`}
	}

	tbody {
		${tw``}
	}

	th {
		${tw`p-4 font-medium`}
	}

	td {
		${tw`bg-gray-200 p-4 font-medium`}
	}
`

export default Post
